import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { MedicalActAttention, MedicalActAttentionOrder, MedicalActPAys, MedicalFragment, medicalActMultiplePays } from '../../models/main/medical-act.model';
import { AttetionsTariffQuantityDto } from 'src/app/pages/clinic-history/dtos/attentions-tariff-quantity-dto';
import { MedicalActAttentionContractPaays } from '../../models/main/medical-act-attention-contract-pays'
import { ServiceOrderDetailInvoice } from 'src/app/models/finance/service-order-one-detail.model';

@Injectable({
  providedIn: 'root'
})
export class MedicalActAttentionService {

  private baseUrl: string = `${environment.apiUrl}/medical-act-attention/`;

  constructor(private http: HttpClient) { }

  report_service_order_tariff_withPatiente(dateSince:string,dateUntil:string,idclinichistory:number):Observable<any>{

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.get(`${this.baseUrl}report_service_order_tariff_withPatiente/${dateSince}/${dateUntil}/${idclinichistory}`, { headers: headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }

  getServiceOrderNew(data:any): Observable<any>{ 
    return this.http.post<any>(`${this.baseUrl}getServiceOrderNew`, data)
  }

  updateMedicalValidateBilling(data:ServiceOrderDetailInvoice[]): Observable<any>{
    return this.http.put<any>(`${this.baseUrl}updateValidateBilling`, data);
  }

  insertMedicalOrder(data: MedicalActAttentionOrder[]): Observable<MedicalActAttention[]> {
    return this.http.post<MedicalActAttention[]>(`${this.baseUrl}createMedicalOrder`, data);
  }

  getByCHPayments(idpatient: number, filters : any): Observable<MedicalActAttention[]> {
    return this.http.post<MedicalActAttention[]>(`${this.baseUrl}by-clinic-history-payments/${idpatient}`,filters)
  }

  countDocuments():Observable<any>{
    return this.http.get<any>(`${this.baseUrl}countDocuments`)
  }
  
  updateStateFragment(idMedical:number):Observable<any>{
    return this.http.put<any>(`${this.baseUrl}updateStateFragment/${idMedical}`,{fraction_of_payment: 1});
  }

  allMedicalFragmentFilter(filters:any,idUser:number):Observable<MedicalFragment[]>{ 

    return this.http.post<MedicalFragment[]>(`${this.baseUrl}allMedicalFragmentFilter/${idUser}`, filters);
  }

  countMedicalActAttentionParts(idUser:number):Observable<any>{
    return this.http.get<any>(`${this.baseUrl}countMedicalActAttentionParts/${idUser}`)
  }

  getQuantityMedical(description:string,idPatient:number):Observable<any>{
    return this.http.get<any>(`${this.baseUrl}QuantityMedical/${description}/${idPatient}`)
  }

  saveMedicalContract(data:MedicalActAttentionContractPaays[]):Observable<MedicalActAttentionContractPaays[]>{
    return this.http.post<MedicalActAttentionContractPaays[]>(`${this.baseUrl}MedicalContracts`,data);
  }

  insert(data: MedicalActAttention): Observable<MedicalActAttention> {
    return null
    //return this.http.post<MedicalActAttention>(`${this.baseUrl}`, data);
  }

  update(ma: MedicalActAttention, id: number): Observable<MedicalActAttention> {
    return this.http.put<MedicalActAttention>(`${this.baseUrl}${id}`, ma);
  }

  updateMedicalActFiles(id:number,files:string):Observable<any>{
    const body = { files };
    return this.http.put<any>(`${this.baseUrl}updateMedicalActFiles/${id}`,body)
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}${id}`)
  }

  getByMedicalAct(id: number): Observable<MedicalActAttention[]> {
    return this.http.get<MedicalActAttention[]>(`${this.baseUrl}by-medical-act/${id}`)
  }


  saveMedicaQuotas(data: medicalActMultiplePays): Observable<medicalActMultiplePays> {
    return this.http.post<medicalActMultiplePays>(`${this.baseUrl}saveMedical`, data);
  }

  /**
   * Obtener las atenciones por historia clincia
   * @param id <historia clinica>
   * @param idoption <doctor o tratamiento>
   * @param option <D = doctor o T = tratamiento>
   * @returns
   */
  getByCH(id: number, idoption: number, option: string): Observable<MedicalActAttention[]> {
    return this.http.get<MedicalActAttention[]>(`${this.baseUrl}by-clinic-history/${id}/${idoption}/${option}`)
  }

  /**
   * Obtiene el reporte en excel de los tratamientos de un paciente
   * @param id <historia clinica>
   * @param idoption <doctor o tratamiento>
   * @param option <D = doctor o T = tratamiento>
   * @returns
   */
  getReportAttentionsByCH(id: number, idoption: number, option: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.get(`${this.baseUrl}report/xls/attentions/${id}/${idoption}/${option}`, { headers: headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }

  getCant(month: number, year: number): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}attention-cant/${month}/${year}`);
  }

  //Reports
  getTreatmentRealized(date: string, bl: number, specialty: number, doctor: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}report-treatment-realized/${date}/${specialty}/${bl}/${doctor}`);
  }

  getTo10Tariff(since: string, until: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}report-top-10-tariff/${since}/${until}`);
  }

  getTop5Specialty(since: string, until: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}report-top-5-specialty/${since}/${until}`);
  }

  getTtoByDoctor(date: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}report-tto-by-doctor/${date}`);
  }

  getPayPatientPdf(filters: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}get-report-pdf-pay-patient/`, filters);
  }

  getPayPatientXlsx(filters: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.post(`${this.baseUrl}get-report-xlsx-pay-patient/`, filters, { headers: headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }

  getQuantityAttentions(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}get-quantity-attentions/${id}`)
  }

  getQuantityAttentionsTariff(id: number): Observable<AttetionsTariffQuantityDto[]> {
    return this.http.get<AttetionsTariffQuantityDto[]>(`${this.baseUrl}get-quantity-attentions/tariff/${id}`)
  }

  getPdfDoctorProduction(filters: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}get-report-pdf-doctor-production`, filters);
  }

  getXlsDoctorProduction(filters: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.post(`${this.baseUrl}get-report-xlsx-doctor-production`, filters, { headers: headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }



  getPdfCashClosing(filters: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/report-payment/closs-box`, filters);
  }

  getXlsCashClosing(filters: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.post(`${environment.apiUrl}/report-payment/get-report-xlsx-report-payment`, filters, { headers: headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }

  getXlsCommissionDoctor(filters: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.post(`${environment.apiUrl}/medical-act-attention/reportXlsCuotasPays`, filters, { headers: headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }

  getXlsCommissionDoctorOI(filters: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('charset', 'utf8');
    return this.http.post(`${environment.apiUrl}/medical-act-attention/reportXlsCuotasPaysOI`, filters, { headers: headers, responseType: 'blob' }).pipe(
      map((response: any) => response),
      catchError(error => throwError(error))
    );
  }
 
  getService_order_payments(idDoctor: any,idpatient: number): Observable<any> {
    const headers = this.http.get(`${environment.apiUrl}/service_billing_payments/getInvoicesMadeByDoctor/${idpatient}/${idDoctor}`);
    return headers
  }

  getService_order_pending(idDoctor: any,idpatient: number): Observable<any> {
    const headers = this.http.get(`${environment.apiUrl}/service_billing_payments/getInvoicesMadeByDoctorPendient/${idpatient}/${idDoctor}`);
    return headers
  }


}
