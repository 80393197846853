import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './auth.guard';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { DoctorDiaryComponent } from './modules/operations/doctor-diary/components/doctor-diary/doctor-diary.component';
import { DoctorDiaryNewComponent } from './modules/operations/doctor-diary-new/doctor-diary-new.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'inicio',
        loadChildren: () => import('./pages/home/home.module').then(module => module.HomeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'campus',
        loadChildren: () => import('./pages/campus/campus.module').then(module => module.CampusModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'campus'
        }
      },
      {
        path: 'business-line',
        loadChildren: () => import('./pages/business-line/business-line.module').then(module => module.BusinessLineModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'business-line'
        }
      },
      {
        path: 'specialty',
        loadChildren: () => import('./pages/specialty/specialty.module').then(module => module.SpecialtyModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'specialty'
        }
      },
      {
        path: 'tariff',
        loadChildren: () => import('./pages/tariff/tariff.module').then(module => module.TariffModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'tariff'
        }
      },
      {
        path: 'clinic-history',
        loadChildren: () => import('./pages/clinic-history/clinic-history.module').then(module => module.ClinicHistoryModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'clinic-history'
        }
      },
      {
        path: 'documents',
        loadChildren: () => import('./pages/documents/documents.module').then(module => module.DocumentsModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'documents'
        }
      },
      {
        path: 'payment-method',
        loadChildren: () => import('./pages/payment-method/payment-method.module').then(module => module.PaymentMethodModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'payment-method'
        }
      },
      {
        path: 'country',
        loadChildren: () => import('./pages/country/country.module').then(module => module.CountryModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'country'
        }
      },
      {
        path: 'doctor',
        loadChildren: () => import('./modules/operations/doctor/doctor.module').then(module => module.DoctorModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'doctor'
        }
      },
      {
        path: 'quotation',
        loadChildren: () => import('./pages/quotation/quotation.module').then(module => module.QuotationModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'quotation'
        }
      },
      {
        path: '',
        redirectTo: '/inicio',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        redirectTo: '/inicio'
        //loadChildren: () => import('./demo/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'layout',
        loadChildren: () => import('./demo/pages/layout/layout.module').then(module => module.LayoutModule)
      },
      {
        path: 'widget',
        loadChildren: () => import('./demo/widget/widget.module').then(module => module.WidgetModule)
      },
      {
        path: 'basic',
        loadChildren: () => import('./demo/ui-elements/ui-basic/ui-basic.module').then(module => module.UiBasicModule)
      },
      {
        path: 'forms',
        loadChildren: () => import('./demo/pages/form-elements/form-elements.module').then(module => module.FormElementsModule)
      },
      {
        path: 'tbl-bootstrap',
        loadChildren: () => import('./demo/pages/tables/tbl-bootstrap/tbl-bootstrap.module').then(module => module.TblBootstrapModule)
      },
      {
        path: 'tbl-datatable',
        loadChildren: () => import('./demo/pages/tables/tbl-datatable/tbl-datatable.module').then(module => module.TblDatatableModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./demo/pages/core-chart/core-chart.module').then(module => module.CoreChartModule)
      },
      {
        path: 'task',
        loadChildren: () => import('./demo/application/task/task.module').then(module => module.TaskModule)
      },
      {
        path: 'invoice',
        loadChildren: () => import('./demo/extension/invoice/invoice.module').then(module => module.InvoiceModule)
      },
      {
        path: 'full-calendar',
        loadChildren: () => import('./demo/extension/full-event-calendar/full-event-calendar.module')
          .then(module => module.FullEventCalendarModule)
      },
      {
        path: 'file-upload',
        loadChildren: () => import('./demo/extension/files-upload/files-upload.module').then(module => module.FilesUploadModule)
      },
      {
        path: 'coin',
        loadChildren: () => import('./pages/coin/coin.module').then(module => module.CoinModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'coin'
        }
      },
      {
        path: 'responsible',
        loadChildren: () => import('./pages/responsible/responsible.module').then(module => module.ResponsibleModule)
      },
      {
        path: 'deparments',
        loadChildren: () => import('./pages/deparments/deparments.module').then(module => module.DeparmentsModule)
      },
      {
        path: 'provinces',
        loadChildren: () => import('./pages/province/province.module').then(module => module.ProvinceModule)
      },
      {
        path: 'districts',
        loadChildren: () => import('./pages/districts/districts.module').then(module => module.DistrictsModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'districts'
        }
      },
      {
        path: 'roles',
        loadChildren: () => import('./profile/roles/roles.module').then(module => module.RolesModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'roles'
        }
      },
      {
        path: 'users',
        loadChildren: () => import('./profile/users/users.module').then(module => module.UsersModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'user'
        }
      },

      {
        path: 'permissions',
        loadChildren: () => import('./profile/permissions/permissions.module').then(module => module.PermissionsModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'permissions'
        }
      },
      {
        path: 'environment-doctor',
        loadChildren: () => import('./pages/environment-doctor/environment-doctor.module').then(module => module.EnvironmentDoctorModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'environment-doctor'
        }
      },
      {
        path: 'programation',
        loadChildren: () => import('./pages/programation/programation.module').then(module => module.ProgramationModule)
      },
      {
        path: 'user-profile',
        loadChildren: () => import('./profile/user/user.module').then(module => module.UserModule)
      },
      {
        path: 'medical-fragment',
        loadChildren: () => import('./medical/medical-act-attention-fragment/medical-act-attention-fragment.module').then(module => module.MedicalActAttentionFragmentModule)
      },
      {
        path: 'new-payments',
        loadChildren: () => import('./new-payments/new-payments.module').then(module => module.NewPaymentsModule)
      },
      {
        path: 'medical-attention/:id',
        loadChildren: () => import('./pages/main/medical-attention/medical-attention.module').then(module => module.MedicalAttentionModule)
      },
      {
        path: 'lab-order',
        loadChildren: () => import('./pages/main/lab-order/lab-order.module').then(module => module.LabOrderModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'lab-order'
        }
      },
      {
        path: 'laboratory-programming',
        loadChildren: () => import('./pages/main/lab-programming/lab-programming.module').then(module => module.LabProgrammingModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'laboratory-programming'
        }
      },
      {
        path: 'diary',
        loadChildren: () => import('./pages/main/diary/diary.module').then(module => module.DiaryModule),
        canActivate: [AuthGuard],
        data: {
          permissions: 'diary'
        }
      },
      {
        path: 'doctor-programming',
        component: DoctorDiaryComponent,
        canActivate: [AuthGuard],
        data: {
          permissions: 'diary'
        }
      },
      {
        path: 'doctor-programming-new',
        component: DoctorDiaryNewComponent,
        canActivate: [AuthGuard],
        data: {
          permissions: 'diary'
        }
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(mod => mod.ReportsModule)
      },
      {
        path: 'finance',
        loadChildren: () => import('./modules/finance/finance.module').then(mod => mod.FinanceModule)
      },
      {
        path: 'operation',
        loadChildren: () => import('./modules/operations/operation.module').then(mod => mod.OperationModule)
      },
      {
        path: 'security',
        loadChildren: () => import('./modules/security/security.module').then(mod => mod.SecurityModule)
      },
      {
        path: 'commercial',
        loadChildren: () => import('./modules/commercial/commercial.module').then(mod => mod.CommercialModule)
      },
      {
        path: 'commercial/controls',
        loadChildren: () => import('./modules/commercial/controls/controls.module').then(m => m.ControlsModule)
      },
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./demo/pages/authentication/authentication.module').then(module => module.AuthenticationModule)
      },
      {
        path: 'maintenance',
        loadChildren: () => import('./demo/pages/maintenance/maintenance.module').then(module => module.MaintenanceModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
