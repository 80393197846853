import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';
import { Programming } from '../../models/programming.model';
import { DoctorDiaryDataService } from '../private/doctor-diary-data.service';

import { DoctorMatrix } from '../../models/doctor-matrix.model';
import { DoctorMatrixDto } from '../../dtos/doctor-matrix-dto';

import { ProgrammingEvent } from '../../models/programming-event.model';
import { ProgrammingEventDto } from '../../dtos/programming-event-dto';

import { EnvirontmentDto } from '../../dtos/environtment-dto';

@Injectable({
    providedIn: 'root'
})
export class DoctorDiaryService {

    private baseUrl: string = `${environment.apiUrl}`;
    private baseUrlAgenda: string = `${environment.apiUrlAgenda}`;

    constructor(
        private dataRequestService: DoctorDiaryDataService,
        private http: HttpClient
    ) { }

    update_breack_doctor(iddoctor:number,since:string,until:string):Observable<any>{
        return this.http.patch<any>(`${this.baseUrl}/doctor-programming/update_breack_doctor/${iddoctor}/${since}/${until}`,null)
    }

    breack_doctor_interval(iddoctor:number,date:string):Observable<any>{
        return this.http.get<any>(`${this.baseUrl}/doctor-programming/breack_doctor_interval/${iddoctor}/${date}`)
      }
    

    get_reserv_free_prueba(iddoctor:number,date:string,interval:number):Observable<any[]>{
        return this.http.get<any[]>(`${this.baseUrl}/doctor-programming/get_reserv_free_prueba/${iddoctor}/${date}/${interval}`);
      }

    get_programing_reserv_doctor_with_patient(idcampus:string,date:string):Observable<any[]>{
        return this.http.get<any[]>(`${this.baseUrlAgenda}/operation/doctor_programming/get_programing_reserv_doctor_with_patient/${idcampus}/${date}`);
      }
 
    getAllEnvirontments(id: number): Observable<EnvirontmentDto[]> {
        return this.http.get<EnvirontmentDto[]>(`${this.baseUrl}/doctor-programming/environtment/${id}`);
    }

    /* Programación de los doctores */
    getProgramming(date: string, idcampus: number): Observable<Programming[]> {
        return this.dataRequestService.getProgramming(date, idcampus).pipe(
            map(ele => {
                const data = ele.map(el => new Programming().FromDTO(el));
                return data;
            })
        );

    }

  
    /* Programación de los doctores */
    getEventProgramming(date: string, idcampus: number): Observable<ProgrammingEvent[]> {
        return this.dataRequestService.getEventProgramming(date, idcampus).pipe(
            map(ele => {
                const data = ele.map(el => new ProgrammingEvent().FromDTO(el));
                return data;
            })
        );

    }

}
