
import { Injectable } from '@angular/core';

import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DoctorDiaryEndpoints {

    constructor() { }

    private apiUrl(): string {
        return `${environment.apiUrl}`;
    }

    getProgramming(date: string, idcampus: number) {
        return `${this.apiUrl()}/doctor-programming/programmin-day/${date}/${idcampus}`;
    }

    getMatrixProgramming(date: string, idcampus: number) {
        return `${this.apiUrl()}/doctor-programming/matrix-programming-day/${idcampus}/${date}`;
    }

    getValidate(group: number, id: number) {
        return `${this.apiUrl()}/doctor-programming/validate/${group}/${id}`;
    }

    getUploadedFiles(group: number, id: number) {
        return `${this.apiUrl()}/doctor-programming/upload/${group}/${id}`;
    }

    getAttentionReport(page: number, limit: number) {
        return `${this.apiUrl()}/doctor-programming/attention/report/${page}/${limit}`;
    }

    getAttentionStages() {
        return `${this.apiUrl()}/doctor-programming/attention/stages`;
    }

    setAttentionStages(id: number, state: number) {
        return `${this.apiUrl()}/doctor-programming/attention/stages/${id}/${state}`;
    }

}