import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';
// Load the full build.
import * as _ from 'lodash';
// Moment
import * as moment from 'moment-timezone';
// SweetAler
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';

// Models
import { Doctor } from 'src/app/modules/operations/doctor/models/doctor.model';
import { CoinModel } from 'src/app/models/coin.model';
import { EnvironmentDoctorModel } from 'src/app/models/environment-doctor.model';
import { ProgramationAppointment } from '../../../models/programming-appointment.model';
import { ClinicHistoryModel } from 'src/app/models/clinic-history.model';
import { TariffModel } from 'src/app/models/tariff.model';
import { MedicalActAttention } from 'src/app/models/main/medical-act.model';
import { UnionDoctorPaymentAttentionModel } from 'src/app/models/union-doctor-patient-attention.model';

// Services
import { ClinicHistoryService } from 'src/app/service/clinic-history.service';
import { TariffService } from 'src/app/service/tariff.service';
import { DoctorService } from 'src/app/modules/operations/doctor/services/public/doctor.service';
import { EnvironmentDoctorService } from 'src/app/service/environment-doctor.service';
import { CoinService } from 'src/app/service/coin.service';
import { ReservationService } from 'src/app/service/main/reservation.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ListSelectDto } from 'src/app/shared/dtos/list-select-dto';
import { ProgramationService } from 'src/app/service/programation.service';
import { UnionDoctorPatientAttentionService } from '../../../../../../service/union-doctor-patient-attention.service';

class FormFields {
  get Patient(): string { return 'Patient'; }
  get Tariff(): string { return 'Tariff'; }
  get Doctor(): string { return 'Doctor'; }
  get Doctor2(): string { return 'Doctor2'; }
  get EnvironmentDoctor(): string { return 'EnvironmentDoctor'; }
  get Date(): string { return 'Date'; }
  get Appointment(): string { return 'Appointment'; }
  get PendingPayment(): string { return 'PendingPayment'; }
  get Amount(): string { return 'Amount'; }
  get Coin(): string { return 'Coin'; }
  get Reason(): string { return 'Reason'; }
  get EnvironmentDoctorOld(): string { return 'EnvironmentDoctorOld'; }
  get DateOld(): string { return 'DateOld'; }
  get AppointmentOld(): string { return 'AppointmentOld'; }
}

@Component({
  selector: 'app-modal-doctor-diary-appointment',
  templateUrl: './modal-doctor-diary-appointment.component.html',
  styleUrls: ['./modal-doctor-diary-appointment.component.scss']
})
export class ModalDoctorDiaryAppointmentComponent implements OnInit {

  @Input() data: any;
  // FORM
  formData: FormGroup;
  formFields: FormFields = new FormFields();

  idpatient: number;

  formInput: ProgramationAppointment = new ProgramationAppointment();
  listPatient: ClinicHistoryModel[] = [];
  loadingSelectPatient = false;
  loadingSelectDoctor2 = false;
  listTariff: TariffModel[] = [];
  listDoctors: ListSelectDto[] = [];
  listDoctors2: Doctor[] = [];
  listCoin: CoinModel[] = [];
  environmentList: EnvironmentDoctorModel[] = [];
  title = 'Nueva Cita';
  reprograming = false;

  // Autocomplet
  searching = false;
  searchFailed = false;
  patient = '';
  showAlert = false;

  showPendingPayment = false;
  time_until: string;

  // Si el usuario viene del modulo de atención
  attention: MedicalActAttention;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private fb: FormBuilder,
    config: NgbModalConfig,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private servicePatient: ClinicHistoryService,
    private serviceTariff: TariffService,
    private serviceDoctor: DoctorService,
    private edService: EnvironmentDoctorService,
    private reservationService: ReservationService,
    private coinService: CoinService,
    private serviceReservation: ProgramationService,
    private serviceUnionDoctorPatientAttention: UnionDoctorPatientAttentionService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.time_until = moment(`${this.data.date} ${this.data.since}`).add(this.data.data.interval, 'minutes').format('HH:mm');
    this.getDoctors();
    this.getTariffs();
    //console.log({ data: this.data });
    this.initForm();
    this.attention = this.reservationService.attention;
    if (this.data.acction) {
      this.get();
      this.title = 'Editar cita';
    }

    this.getEnvironment();
    this.onGetDoctors2();

    if (sessionStorage.getItem('oldreservation')) {
      this.title = 'Reprogramar cita';
      this.reprograming = true;
      this.get();
    }
    if (this.attention) {
      this.patient = `${this.attention.patient.documentNumber} ${this.attention.patient.name} ${this.attention.patient.lastNamefather} ${this.attention.patient.lastNameMother}`;
      this.formInput.patient = this.attention.patient.id
    }

    this.getCoin();
  }

  initForm() {
    this.formData = this.fb.group({});
    this.formData.addControl(this.formFields.Patient, new FormControl(null, [Validators.required]));
    this.formData.addControl(this.formFields.Tariff, new FormControl(null, [Validators.required]));
    this.formData.addControl(this.formFields.Doctor, new FormControl(this.data.mainData.iddoctor, [Validators.required]));
    this.formData.addControl(this.formFields.Doctor2, new FormControl(null));
    this.formData.addControl(this.formFields.EnvironmentDoctor, new FormControl(this.data.environtment, [Validators.required]));
    this.formData.addControl(this.formFields.EnvironmentDoctorOld, new FormControl(null));
    this.formData.addControl(this.formFields.Date, new FormControl(this.data.date, [Validators.required]));
    this.formData.addControl(this.formFields.DateOld, new FormControl(null));
    this.formData.addControl(this.formFields.Appointment, new FormControl(`${this.data.since}:00-${this.time_until}:00`, [Validators.required]));
    this.formData.addControl(this.formFields.AppointmentOld, new FormControl(null));
    this.formData.addControl(this.formFields.PendingPayment, new FormControl(false));
    this.formData.addControl(this.formFields.Amount, new FormControl(null));
    this.formData.addControl(this.formFields.Coin, new FormControl(null));
    this.formData.addControl(this.formFields.Reason, new FormControl(null));
  }

  getTariffs(): void { 
    this.listTariff = [];
    //const environ = _.find(this.environmentList, {id: this.formInput.environment});
    this.serviceTariff.getToDiary()
      .subscribe(
        res => {
          res.forEach((tariff: TariffModel) => {
            this.listTariff.push(tariff);
          }); 
        },
        err => { 
          console.log(err.error);
        }
      );
  }

  
  getDoctors() {
    
    this.listDoctors = [];
    this.serviceDoctor.getAll()
      .subscribe(
        (res: Doctor[]) => {
          this.listDoctors = <any>res; 
        },
        (err: HttpErrorResponse) => { 
          console.log(err.error);
        }
      );
  }


  /**
   * Obtenemos solo los doctores
   * OI del día
   */
  onGetDoctors2(): void {
    this.loadingSelectDoctor2 = true;
    this.listDoctors2 = [];
    this.serviceDoctor.getByBl([2], moment(this.data.date).day())
      .subscribe(
        res => {
          // Obtenemos solo los doc
          this.loadingSelectDoctor2 = false;
          this.listDoctors2 = res;
        },
        (err: HttpErrorResponse) => {
          this.loadingSelectDoctor2 = false;
          this.toastr.error(
            'Ocurrio un error al obtener los dotores de OI',
            'Ateción',
            { timeOut: 4000, progressBar: true, closeButton: true }
          );
        }
      );
  }

  getEnvironment(): void {
    this.environmentList = [];
    this.edService.getAll().subscribe(
      res => {
        this.environmentList = res;

      },
      err => {
        console.error('Error al obtener los consultorios');
      }
    );
  }

  addAppointment(): void {
    if (this.formData.invalid) {
      return;
    }
    this.spinner.show();
    const data = this.setData();
    ///console.log(data);
    
    if (this.data.acction) {
      data.id = this.formInput.id;
      this.serviceReservation.update(data, this.formInput.id).subscribe(
        res => {
          this.spinner.hide();
          this.toastr.success(
            'La reprogramación fue registrada correctamente',
            'Ok!',
            { timeOut: 3000, progressBar: true }
          );
          sessionStorage.removeItem('oldreservation');
          this.activeModal.close('Save click');
          this.reservationService.attention = null;
        },
        err => {
          this.spinner.hide();
          this.toastr.error(
            'Ocurrio un error al registrar la cita',
            'Atención',
            { timeOut: 3000, progressBar: true }
          );
        }
      );
    } else {

      this.serviceReservation.insert(data).subscribe(
        res => {
          //console.log('respuesta',res);
          let union : UnionDoctorPaymentAttentionModel =  {
            id_clinic_history: data.patient,
            id_reservation: res.id,
            id_doctor_attention: data.doctor
          }

          this.serviceUnionDoctorPatientAttention.search_patient(data.patient).subscribe(
            res =>{
              //console.log('respuesta2',res);
              if(res !== null){
                union.id_doctor_charge = res.id_doctor
              }
              
              this.serviceUnionDoctorPatientAttention.insert_pattient_for_attention(union).subscribe(
                resp =>{
                  //console.log('respuesta3',resp);
                  
                }
              )
            }
          )
          this.spinner.hide();
          this.toastr.success(
            'La reserva fue registrada correctamente',
            'Ok!',
            { timeOut: 3000, progressBar: true }
          );
          this.activeModal.close('Save click');
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          if (err.status === 400) {
            this.toastr.warning(
              err.error.message,
              'Atención',
              { timeOut: 3500, progressBar: true }
            );
          } else {
            this.toastr.error(
              'Ocurrio un error al registrar la cita',
              'Atención',
              { timeOut: 3500, progressBar: true }
            );
          }
        }
      );
    }


  }

  setData(): ProgramationAppointment {
    const data: ProgramationAppointment = new ProgramationAppointment();
    data.environment = this.formData.get(this.formFields.EnvironmentDoctor).value;
    data.date = this.formData.get(this.formFields.Date).value;
    data.appointment = this.formData.get(this.formFields.Appointment).value;
    data.pending_payment = this.formData.get(this.formFields.PendingPayment).value;
    data.amount = this.formData.get(this.formFields.Amount).value;
    data.coin = this.formData.get(this.formFields.Coin).value;
    data.tariff = this.formData.get(this.formFields.Tariff).value;
    data.reason = this.formData.get(this.formFields.Reason).value;
    data.doctor = this.formData.get(this.formFields.Doctor).value;
    data.doctor_id2 = this.formData.get(this.formFields.Doctor2).value;
    data.id = null;
    data.qdetail = 0;
    data.state = 1;
    data.patient = this.idpatient;
    data.since = `${this.data.since}:00`;
    data.until = `${this.time_until}:00`;
    data.interval = this.data.data.interval;
    return data;
  }

  cancelRepro(): void {
    this.toastr.info(
      'Ok!', 'Reprogramación Cancelada', { timeOut: 3000, progressBar: true }
    );
    sessionStorage.removeItem('oldreservation');
    this.activeModal.close('Save click');
  }

  get(): void {
    let id: number;
    if (this.data.acction && this.data.acction === 'edit') {
      id = this.data.id;
    } else {
      id = Number(sessionStorage.getItem('oldreservation'));
      this.data.acction = 're-programming';
    }
    this.serviceReservation.getOne(id)
      .subscribe(
        (res: any) => {
          this.formData.get(this.formFields.Patient).setValue(res.patient);
          this.formData.get(this.formFields.Tariff).setValue(res.idtariff);
          this.formData.get(this.formFields.Doctor2).setValue(res.iddoctor2);
          this.formData.get(this.formFields.EnvironmentDoctorOld).setValue(res.idenvironment);
          this.formData.get(this.formFields.DateOld).setValue(moment(res.date).format('YYYY-MM-DD'));
          this.formData.get(this.formFields.AppointmentOld).setValue(res.appointment);
          this.formInput.id = res.id;
          this.formInput.patient = res.idpatient;
          this.idpatient = res.idpatient;
          this.patient = res.patient;
          this.formInput.doctor = res.iddoctor;
          this.formInput.doctor_id2 = res.iddoctor2;
          if (res.idtariff) {
            this.formInput.tariff = res.idtariff;
          }
          this.formInput.pending_payment = res.pending_payment;
          this.formInput.amount = res.amount;
          this.formInput.coin = res.idcoin;
          this.formInput.reason = res.reason;
        },
        err => {
          console.log('Error al buscar la reserva');
        }
      );
  }

  validateDoctor(): void {
    this.spinner.show();
    /* this.serviceReservation.validateDoctor(this.formInput.doctor, this.formInput.date, this.formInput.appointment)
      .subscribe(
        res => {
          this.spinner.hide();
          if (res === 1) {
            this.formInput.doctor = null;
            Swal.fire({
              title: 'Atención!!!!',
              text: 'El doctor ya tiene una reserva a la misma hora en otro consultorio',
              type: 'warning',
            });
          } else if (res === 2) {
            this.formInput.doctor = 0;
            Swal.fire({
              title: 'Atención!!!!',
              text: 'El doctor ya tiene bloqueado ese horario',
              type: 'warning',
            });
          }
        },
        err => {
          this.spinner.hide();
          console.log('Errro Validación ');
        }
      ); */
  }

  /* SELECT DEL AUTO COMPLETADO */
  selectedItem(it: any): void {
    this.showAlert = false;
    const { item } = it;
    this.idpatient = item.id;
    if (Number(item.quotasPending) > 0) {
      this.showAlert = true;
    }
  }

  /* AUTOCOMPLETADO DE LOS MEDICAMENTOS **/
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.servicePatient.search(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

  /**
   * Used to format the result data from the lookup into the
   * display and list values. Maps `{name: 'band', id:'id' }` into a string
   */
  resultFormatBandListValue(value: any): string {
    return value.name;
  }
  /**
   * Initially binds the string value and then after selecting
   * an item by checking either for string or key/value object.
   */
  inputFormatBandListValue(value: any): any {
    if (value.name) {
      return value.name;
    }
    return value;
  }

  getCoin(): void {
    this.listCoin = [];
    this.coinService.coinList$.pipe(
      takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.listCoin = data;
      });
  }

  setPendingPayment(): void {
    this.showPendingPayment = this.formData.get(this.formFields.PendingPayment).value;
    if (this.showPendingPayment) {
      this.formData.get(this.formFields.Amount).setValidators([Validators.required]);
      this.formData.get(this.formFields.Coin).setValidators([Validators.required]);
    } else {
      this.formData.get(this.formFields.Amount).clearValidators();
      this.formData.get(this.formFields.Coin).clearValidators();
      this.formData.get(this.formFields.Amount).setValue(null);
      this.formData.get(this.formFields.Coin).setValue(null);
    }
  }

  getErrorMessage(control: string): boolean {
    if (this.formData.controls[control].hasError('required') && this.formData.controls[control].touched) {
      return true;
    }
    return false;
  }

}
