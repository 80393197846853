import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';

import { DoctorDiaryService } from "../../services/public/doctor-diary.service";
import { Programming } from '../../models/programming.model';

@Component({
  selector: 'app-doctor-diary',
  templateUrl: './doctor-diary.component.html',
  styleUrls: ['./doctor-diary.component.scss']
})
export class DoctorDiaryComponent implements OnInit {

  programming: Programming[] = [];
  session: any = {};
  showFilters = false;
  loading = false;

  constructor(
    private store: Store<{ session: any }>,
    private service: DoctorDiaryService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.getSession();
  }

  getSession(): void {
    this.store.select('session')
      .subscribe(sess => {
        if (sess.id) {
          this.session = sess;
          this.showFilters = true;
          if (sess.doctor) {
            // this.filterDoctor = sess.doctor.id;
          }
        }
      });
  }

  setSchedule(event: any): void {
    this.loading = true;
    const idcampus = this.session.idcampus;
    this.programming = [];
    this.spinner.show();
    
    this.service.getProgramming(event, idcampus).subscribe({
      next: (resp) => {
        //console.log('respuesta',resp);
        
        this.loading = false;
        this.programming = resp;
        this.spinner.hide();
      },
      error: (error) => {
        console.log({ error });
        this.loading = false;
      }
    });
  }

}
