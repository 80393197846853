import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserI } from '../models/user';
import { JwtResponseI } from '../models/jwt-response';
import { tap } from 'rxjs/operators';
import { Observable, BehaviorSubject, observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { PermissionsModel } from '../models/profile/permissions.model';
import { Store } from '@ngrx/store';
import { PermissionsService } from './permissions.service';
import { ModulesPermissions } from '../models/security/modules-permissions.model'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authSubject = new BehaviorSubject(false);
  private token: string;
  public permissions: PermissionsModel[];

  private baseUrl = `${environment.apiUrl}`;

  constructor(
    private http: HttpClient,
    private router: Router,
    private _permissions: PermissionsService,
    private store: Store<{ session: any }>,
  ) {
    localStorage.clear();
    //sessionStorage.clear();
    this.clearCookies();
   }

   private clearCookies(): void {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }
  
  login(user: UserI): Observable<JwtResponseI> {
    return this.http.post<JwtResponseI>(`${this.baseUrl}/auth/signin`, user).pipe(tap(
      (res: JwtResponseI) => {
        if (res) {
          // guardar token
          this.saveToken(res.token);
        }
      })
    );
  }

  loguot(): void {
    this.token = '';
    sessionStorage.clear()
    this.router.navigateByUrl('/auth/signin');
  }

  loggedIn(): boolean {
    const token = this.getToken();
    return !!token;
  }

  private saveToken(token: string): void {
    //localStorage.setItem("ACCESS_TOKEN",token);
    sessionStorage.setItem("ACCESS_TOKEN", token);
    this.token = token
  }


  public getToken(): string {
    if (!this.token) {
      //this.token = localStorage.getItem("ACCESS_TOKEN");
      this.token = sessionStorage.getItem("ACCESS_TOKEN");
    }
    return this.token;
  }

  hasPermissionsView(permission: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const iduser = Number(sessionStorage.getItem('iduser'));
      this._permissions.getPermissionsByUser(iduser).subscribe((per: any[]) => {
        var res: boolean = false;
        per.forEach(p => {
          if (p.mpermissions.page == permission) {
            if (p.view)
              res = true
          }
        });
        observer.next(res)
      })
    })
  }


  hasPermissionsDelete(permission: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const iduser = Number(sessionStorage.getItem('iduser'));
      this._permissions.getPermissionsByUser(iduser).subscribe((per: any[]) => {
        var res: boolean = false;
        per.forEach(p => {
          if (p.mpermissions.page == permission) {
            if (p.delete)
              res = true
          }
        });
        observer.next(res)
      })
    })
  }

  hasPermissionsUpdate(permission: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const iduser = Number(sessionStorage.getItem('iduser'));
      this._permissions.getPermissionsByUser(iduser).subscribe((per: any[]) => {
        var res: boolean = false;
        per.forEach(p => {
          if (p.mpermissions.page == permission) {
            if (p.update)
              res = true
          }
        });
        observer.next(res)
      })
    })
  }

  hasPermissionsInsert(permission: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const iduser = Number(sessionStorage.getItem('iduser'));
      this._permissions.getPermissionsByUser(iduser).subscribe((per: any[]) => {
        var res: boolean = false;
        per.forEach(p => {
          if (p.mpermissions.page == permission) {
            if (p.insert)
              res = true
          }
        });
        observer.next(res)
      })
    })
  }

  getAutorization(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/users/autorization`, data);
  }

  hasPermission(code: string): Observable<ModulesPermissions> {
    return this.http.get<ModulesPermissions>(`${this.baseUrl}/modules-user/get-permission-module/${code}`);
  }


}
