import { NgModule } from '@angular/core';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../theme/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectModule } from '@ng-select/ng-select';
import { TableModule } from 'primeng-lts/table';
import { ButtonModule } from 'primeng-lts/button';
import { ToolbarModule } from 'primeng-lts/toolbar';
import {MultiSelectModule} from 'primeng-lts/multiselect';

import { AddOdontogramaComponent } from './add-odontograma/add-odontograma.component';
import { OdontogramasComponent } from './odontogramas/odontogramas.component';
import { ClinicHistoryNotesComponent } from './clinic-history/clinic-history-notes/clinic-history-notes.component';
import { MedicalActComponent } from './medical-act/medical-act.component';
import { AddClinicHistoryNotesComponent } from './add-clinic-history-notes/add-clinic-history-notes.component';
import { PrescriptionComponent } from './prescription/prescription.component';
import { MedicalActFilesComponent } from './medical-act-files/medical-act-files.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { ClinicHistoryFilesComponent } from './clinic-history/clinic-history-files/clinic-history-files.component';
import { ClinicHistoryVouchersComponent } from './clinic-history/clinic-history-vouchers/clinic-history-vouchers.component';
import { ClinicHistoryQuotationsComponent } from './clinic-history/clinic-history-quotations/clinic-history-quotations.component';
import { ClinicHistoryQuotesComponent } from './clinic-history/clinic-history-quotes/clinic-history-quotes.component';
import { DashboardDoctorComponent } from './dashboards/dashboard-doctor/dashboard-doctor.component';
import { DashboardComponent } from './dashboards/dashboard/dashboard.component';
import { OdontogramaComponent } from './odontograma/odontograma.component';
import { DashboardAssistantComponent } from './dashboards/dashboard-assistant/dashboard-assistant.component';
import { DashboardReceptionComponent } from './dashboards/dashboard-reception/dashboard-reception.component';
import { DashboardBasicComponent } from './dashboards/dashboard-basic/dashboard-basic.component';
import { AttentionsComponent } from './attentions/attentions.component';
import { AnamnesisComponent } from './clinic-history/anamnesis/anamnesis.component';
import { MedicalAppointmentComponent } from './medical-appointment/medical-appointment.component';
import { AppointmentDetailComponent } from './appointment-detail/appointment-detail.component';
import { AttentionComponent } from './attention/attention.component';
import { ViewPdfComponent } from './view-pdf/view-pdf.component';
import { LabStateComponent } from './lab-state/lab-state.component';

// Pipes
import { PipesModule } from '../pipes/pipes.module';
import { MouthBreathingComponent } from './clinic-history/mouth-breathing/mouth-breathing.component';
import { QuotationTermsComponent } from './quotation-terms/quotation-terms.component';
import { DiaryLockComponent } from './diary-lock/diary-lock.component';
import { DiaryListComponent } from './diary-list/diary-list.component';
import { AuditComponent } from './audit/audit.component';
import { ItemProgramationDashboardComponent } from './item-programation-dashboard/item-programation-dashboard.component';
import { ModalAddAttentionComponent } from './attentions/modal-add-attention/modal-add-attention.component';
import { CardAddAttentionComponent } from './attentions/card-add-attention/card-add-attention.component';
import { ModalAutorizationComponent } from './security/modal-autorization/modal-autorization.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ModalAddAttentionNewVersionComponent } from './attentions/modal-add-attention-new-version/modal-add-attention-new-version.component';
import { ModalDiscountSpecificationComponent } from './attentions/modal-add-attention-new-version/modal-discount-specification/modal-discount-specification.component';
import { CheckboxModule } from 'primeng-lts/checkbox';
import { InputTextModule } from 'primeng-lts/inputtext';
import { ModalSelectQuotaComponent } from './attentions/modal-add-attention-new-version/modal-select-quota/modal-select-quota.component';
import { ModalUpdateAttentionComponent } from './attentions/modal-update-attention/modal-update-attention.component';
import { UnionPaymenAttentionComponent } from './appointment-detail/union-paymen-attention/union-paymen-attention.component';




@NgModule({
  declarations: [
    AddOdontogramaComponent,
    OdontogramasComponent,
    ClinicHistoryNotesComponent,
    MedicalActComponent,
    AddClinicHistoryNotesComponent,
    PrescriptionComponent,
    MedicalActFilesComponent,
    ClinicHistoryFilesComponent,
    ClinicHistoryVouchersComponent,
    ClinicHistoryQuotationsComponent,
    ClinicHistoryQuotesComponent,
    DashboardDoctorComponent,
    DashboardComponent,
    OdontogramaComponent,
    DashboardAssistantComponent,
    DashboardReceptionComponent,
    DashboardBasicComponent,
    AttentionsComponent,
    AnamnesisComponent,
    MedicalAppointmentComponent,
    AppointmentDetailComponent,
    AttentionComponent,
    ViewPdfComponent,
    MouthBreathingComponent,
    QuotationTermsComponent,
    DiaryLockComponent,
    DiaryListComponent,
    AuditComponent,
    ItemProgramationDashboardComponent,
    LabStateComponent,
    ModalAddAttentionComponent,
    CardAddAttentionComponent,
    ModalAutorizationComponent,
    UploadFileComponent,
    ModalAddAttentionNewVersionComponent,
    ModalDiscountSpecificationComponent,
    ModalSelectQuotaComponent,
    ModalUpdateAttentionComponent,
    UnionPaymenAttentionComponent,
    
    
  ],
  exports: [
    AddOdontogramaComponent,
    OdontogramasComponent,
    ClinicHistoryNotesComponent,
    MedicalActComponent,
    PrescriptionComponent,
    MedicalActFilesComponent,
    ClinicHistoryFilesComponent,
    ClinicHistoryVouchersComponent,
    ClinicHistoryQuotationsComponent,
    ClinicHistoryQuotesComponent,
    DashboardDoctorComponent,
    DashboardComponent,
    OdontogramaComponent,
    DashboardAssistantComponent,
    DashboardReceptionComponent,
    DashboardBasicComponent,
    AttentionsComponent,
    AnamnesisComponent,
    AttentionComponent,
    MouthBreathingComponent,
    AuditComponent,
    ItemProgramationDashboardComponent,
    LabStateComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgbModule,
    FileUploadModule,
    NgSelectModule,
    DataTablesModule,
    NgbNavModule,
    PipesModule,
    ReactiveFormsModule,
    TableModule,
    ButtonModule,
    ToolbarModule,
    MatSlideToggleModule,
    MultiSelectModule,
    CheckboxModule,
    InputTextModule
  ]
})
export class ComponentsModule { }
